.panel-button {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: none;
}

.panel-overlay.open {
  display: block;
}

.panel-content {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background: #191e2e;
  z-index: 1002;
  transition: right 0.3s ease-in-out;
  padding: 10px;
  box-sizing: border-box;
  font-family: Arial;
}

.panel-content.open {
  right: 0;
}

.panel-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
}

.panel-close-button:hover {
  background-color: #f0f0f0;
}

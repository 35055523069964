.loader-container {
  height: 100vh;
  width: 100vw;
  background: var(
    --background,
    linear-gradient(180deg, #010101 0%, #020914 100%)
  );
  display: grid;
  place-items: center;
}

.welcome-page-container {
  height: 100vh;
  width: 100vw;
  background: var(
    --background,
    linear-gradient(180deg, #010101 0%, #020914 100%)
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 80px;
}

.welcome-text {
  text-align: center;
}

.welcome-text span {
  color: #fff;
  font-size: 30px;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.welcome-text p {
  color: #62656b;
  text-align: center;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.welcome-page-container .primary-button {
  margin-bottom: 15px;
}

.organization-card {
  width: 90vw;
  height: 40px;
  border-radius: 15px;
  margin-top: 20px;
  background: #0f141e;
  transition: 0.2s;
}

.organization-card span {
  color: #fff !important;
  font-size: 14px !important;
  margin-left: 20px !important;
  font-family: Arial !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 40px !important;
}

.selected {
  border: #267ee5;
  border-style: solid;
  border-width: 1px;
}

body,
html {
  color: #fff;
  margin: 0;
  padding: 0;
}

a {
  color: #87ceeb;
  text-decoration: none;
}

a:hover {
  color: #87ceeb;
  text-decoration: underline;
}

.primary-button {
  background-color: #267ee5;
  border-radius: 50px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #fff;
  border: none;
  font-size: 14px;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}

.secondary-button {
  background-color: #232b3f;
  border-radius: 50px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #fff;
  border: none;
  font-size: 14px;
  font-family: Arial;
  font-style: normal;
  line-height: normal;
  text-decoration: none;
}

.primary-input {
  background: #191e2e;
  border-radius: 50px;
  height: 25px;
  border: none;
  color: white;
  padding-left: 10px;
}

.primary-input:focus {
  outline: none;
}

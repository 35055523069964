.chat-input-container {
  width: 100vw;
  max-height: 90px;
  padding: 10px;
  background: #202533;
  justify-self: flex-end;
  display: grid;
}

.chat-input-container textarea {
  border-radius: 10px;
  width: 85%;
  max-height: 70px;
  background: #0f141e;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  resize: none;
}

.chat-input-container textarea:focus {
  outline: none;
}

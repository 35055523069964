.reference-card {
  position: relative;
  display: inline-block;
  width: 90vw;
  height: 40px;
  border-radius: 15px;
  margin-top: 10px;
  background: #0f141e;
  transition: 0.2s;
}

.reference-card a {
  font-size: 12px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  line-height: 40px !important;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: -webkit-fill-available;
}

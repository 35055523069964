.chat-container {
  height: 100vh;
  width: 100vw;
  background: #191e2e;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 20px; */
}

.message-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

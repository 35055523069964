.reference-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.reference-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.message {
  margin-top: 20px;
  padding: 8px 8px !important;
  height: fit-content;
  color: white !important;
  width: fit-content;
  max-width: 85%;
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
}

.user {
  align-self: flex-end;
  border-radius: 10px 0px 10px 10px;
  margin-right: 10px;
  background-color: #2977ef !important;
}

.assistant {
  align-self: flex-start;
  border-radius: 0px 10px 10px 10px;
  margin-left: 10px;
  background-color: #232b3f !important;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-top: 10px;
  max-width: 60%;
  margin-left: 10px;
  justify-content: space-evenly;
}

.buttons-container .secondary-button {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
}

.buttons-container .primary-button {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
}

ul {
  padding-left: 20px;
  margin: 0px;
}

.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
